<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <p>
          Grazie per avere utilizzato il servizio di videoconferenza.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StopViDe"
};
</script>
